import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { IconButton, Typography } from "@mui/material";
import { FHButton } from "../FHButton";
import classNames from "classnames";

const useStyles = (
  stickyBackgroundColour: string,
  primaryCTABackgroundColour: string,
  stickyTextColour: string,
  primaryCTATextColour: string,
) =>
  makeStyles((theme) => ({
    root: {
      position: "relative",
      width: "100%",
    },
    rootWrap: {
      position: "relative",
      padding: theme.spacing(1.1, 4, 1.2, 1.5),
      backgroundColor: stickyBackgroundColour,
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.up("sm")]: {
        textAlign: "center",
        padding: theme.spacing(1),
        justifyContent: "center",
      },
    },
    signUpBtn: {
      backgroundColor: `${primaryCTABackgroundColour} !important`,
      borderColor: `${primaryCTABackgroundColour} !important`,
      color: `${primaryCTATextColour} !important`,
      padding: theme.spacing(0.1, 2),
      maxWidth: "fit-content",
      [theme.breakpoints.up("sm")]: {
        maxWidth: 295,
        width: "auto",
        marginTop: 0,
        marginLeft: theme.spacing(2),
      },
      "&:hover": {
        backgroundColor: `${primaryCTABackgroundColour} !important`,
        borderColor: `${primaryCTABackgroundColour} !important`,
      },
    },
    closeBtn: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1.5),
      color: theme.palette.text.primary,
      padding: 0,
      [theme.breakpoints.up("md")]: {
        top: "50%",
        right: theme.spacing(2.5),
        marginTop: theme.spacing(-1.2),
      },
    },
    showPaddingTop: {
      marginTop: theme.spacing(2.5),
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(3.5),
      },
    },
    showPaddingBottom: {
      marginBottom: theme.spacing(2.5),
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(3.5),
      },
    },
    message: {
      color: stickyTextColour,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
        lineHeight: "16px",
      },
    },
    messageGroup: {
      display: "block",
      [theme.breakpoints.down("sm")]: {
        paddingRight: theme.spacing(1),
      },
    },
  }))();

export type BannerStickyProps = {
  message: string;
  messageLine2?: string;
  primaryCTALabel?: string;
  primaryCTAUrl?: string;
  primaryCTABackgroundColour?: string;
  primaryCTATextColour?: string;
  closeButtonIcon?: JSX.Element;
  stickyBackgroundColour: string;
  stickyTextColour: string;
  onCloseBtnClick?: () => void;
  showPaddingTop?: boolean;
  showPaddingBottom?: Boolean;
};

export const BannerSticky: React.FC<BannerStickyProps> = ({
  message,
  messageLine2,
  primaryCTALabel,
  primaryCTAUrl,
  primaryCTABackgroundColour = "#307974",
  primaryCTATextColour = "#2B2B2B",
  closeButtonIcon,
  stickyBackgroundColour,
  stickyTextColour = "#2B2B2B",
  onCloseBtnClick = () => {},
  showPaddingTop = false,
  showPaddingBottom = false,
}: PropsWithChildren<BannerStickyProps>) => {
  const classes = useStyles(
    stickyBackgroundColour,
    primaryCTABackgroundColour,
    stickyTextColour,
    primaryCTATextColour,
  );

  return (
    <div
      className={classNames(classes.root, {
        [classes.showPaddingTop]: showPaddingTop,
        [classes.showPaddingBottom]: showPaddingBottom,
      })}
    >
      <div className={classes.rootWrap}>
        <div className={classes.messageGroup}>
          <Typography className={classes.message}>{message}</Typography>
          {!!messageLine2 && (
            <Typography className={classes.message}>{messageLine2}</Typography>
          )}
        </div>
        {!!primaryCTAUrl && !!primaryCTALabel && (
          <FHButton
            className={classes.signUpBtn}
            fullWidth={false}
            fhSize="sm"
            href={primaryCTAUrl}
            onClick={() => onCloseBtnClick()}
          >
            <strong>{primaryCTALabel}</strong>
          </FHButton>
        )}
        {!!closeButtonIcon && (
          <IconButton
            className={classes.closeBtn}
            onClick={() => onCloseBtnClick()}
            aria-label="Close"
          >
            {closeButtonIcon}
          </IconButton>
        )}
      </div>
    </div>
  );
};
export default BannerSticky;
