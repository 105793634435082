import React, { PropsWithChildren } from "react";
import classnames from "classnames";
import { makeStyles } from "@mui/styles";
import { FHButtonDropdown, FHButtonDropdownProps } from "../FHButtonDropdown";

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(1, 3, 0.9),
    borderWidth: 1,
    borderStyle: "solid",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(0.75),
      paddingRight: theme.spacing(0.75),
      minWidth: "auto",
    },

    "& .MuiButton-startIcon": {
      [theme.breakpoints.down("md")]: {
        margin: 0,
      },
    },
  },
  text: {
    display: "inline",
  },
}));

export type HeaderButtonProps = FHButtonDropdownProps;

export const HeaderButton: React.FC<HeaderButtonProps> = ({
  children,
  className,
  ...props
}: PropsWithChildren<HeaderButtonProps>) => {
  const classes = useStyles();

  return (
    <FHButtonDropdown
      fullWidth={false}
      className={classnames(className, classes.button)}
      {...props}
    >
      <span className={classes.text}>{children}</span>
    </FHButtonDropdown>
  );
};
