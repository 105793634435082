import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { FHButton } from "../FHButton";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      width: "100%",
      maxWidth: 420,
    },
  },
  titleContainer: {
    padding: theme.spacing(1.5, 2, 0),
    marginBottom: theme.spacing(1),
    "& p": {
      fontSize: theme.typography.h4.fontSize,
    },
  },
  descriptionContainer: {
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
  },
  actionsContainer: {
    padding: theme.spacing(0, 2, 1.5),
    "& button": {
      [theme.breakpoints.down("sm")]: {
        padding: `${theme.spacing(0.4, 1)} !important`,
      },
    },
  },
  button: {
    padding: theme.spacing(0.4, 1),
    height: 40,
    fontSize: 16,
    borderRadius: 7,
  },
}));

export type FHModalProps = {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  title: string;
  description: JSX.Element;
  confirmCTALabel: string;
  cancelCTALabel: string;
};

export const FHModal: React.FC<FHModalProps> = ({
  open,
  handleClose,
  handleConfirm,
  title,
  description,
  confirmCTALabel,
  cancelCTALabel,
}: FHModalProps) => {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.root}
      open={open}
      onClose={handleClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      data-testid="FHModalRoot"
    >
      <DialogTitle
        id="confirmation-dialog-title"
        className={classes.titleContainer}
      >
        <Typography variant="h4">
          <Typography variant="body2">
            <strong data-testid="FHModalTitle">{title}</strong>
          </Typography>
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.descriptionContainer}>
        {description}
      </DialogContent>
      <DialogActions className={classes.actionsContainer}>
        <FHButton
          onClick={handleClose}
          fhStyle="secondary"
          data-testid="FHModalCancelCTA"
          fullWidth
          fhSize="md"
        >
          {cancelCTALabel}
        </FHButton>
        <FHButton
          onClick={() => {
            handleClose();
            handleConfirm();
          }}
          data-testid="FHModalConfirmCTA"
          fullWidth
          fhSize="md"
        >
          {confirmCTALabel}
        </FHButton>
      </DialogActions>
    </Dialog>
  );
};
