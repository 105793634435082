import React, { PropsWithChildren, useState } from "react";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  List,
  ListItem,
  ListItemIcon,
  Paper,
  ClickAwayListener,
} from "@mui/material";
import { useIsIE } from "../../../hooks/hooks";
import { FHButton, FHButtonProps } from "../FHButton";
import classnames from "classnames";

const dividerBorderPadding = 24;

const useStyles = makeStyles((theme) => ({
  selectionList: {
    position: "absolute",
    zIndex: 4000,
    left: -1,
    top: "100%",
    minWidth: "calc(100% + 2px)",
    padding: theme.spacing(1, 0),
    boxSizing: "border-box",
    marginTop: theme.spacing(0.4),
    whiteSpace: "nowrap",
    color: `${theme.palette.text.primary}`,
    "& .MuiListItemIcon-root": {
      marginRight: theme.spacing(0.8),
      minWidth: "auto",
    },
    "& svg": {
      color: theme.palette.text.primary,
      fontSize: 20,
    },
    "& .MuiListItemText-primary": {
      color: `${theme.palette.text.primary}`,
    },
    "& .MuiList-root:first-child": {
      paddingTop: 0,
    },
    "& .MuiList-root:last-child": {
      paddingBottom: 0,
    },
    "& .MuiListItem-gutters": {
      paddingLeft: theme.spacing(2.1),
      paddingRight: theme.spacing(2.1),
    },
  },
  button: {
    transition: "none",
    overflow: "visible",
    color: `${theme.palette.common.white} !important`,
    borderColor: theme.palette.common.white,
    backgroundColor: "transparent !important",
    "&:before": {
      borderColor: `${theme.palette.common.white} !important`,
    },
    "&:hover, &:focus": {
      "&:before": {
        borderColor: `${theme.palette.common.white} !important`,
      },
    },
    "&:active": {
      "&:before": {
        boxShadow: "none !important",
      },
    },
  },
  buttonExpanded: {
    color: `${theme.palette.text.primary} !important`,
    background: `${theme.palette.background.paper} !important`,
    boxShadow: "0px 1px 7px 0 rgb(0 0 0 / 20%) !important",
    "&::after": {
      position: "absolute",
      zIndex: 4001,
      top: "100%",
      content: '" "',
      left: -1,
      width: `calc(100% - ${dividerBorderPadding * 2 - 2}px)`, // the -2 is to compensate for the underlaying button's 1px border
      border: `8px solid ${theme.palette.background.paper}`,
      borderLeftWidth: dividerBorderPadding,
      borderRightWidth: dividerBorderPadding,
      marginTop: -7,
      height: 1,
      background: theme.palette.background.paper,
      boxSizing: "content-box",
    },
    "&:hover": {
      "&::before": {
        border: `2px solid ${theme.palette.background.default} !impo`,
      },
    },
  },
}));

export type FHButtonDropdownItem = {
  id: string;
  icon: JSX.Element;
  label: string;
};

export type FHButtonDropdownProps = FHButtonProps & {
  selectionList?: FHButtonDropdownItem[];
  onSelectedItem?: (item: FHButtonDropdownItem) => void;
};

export const FHButtonDropdown: React.FC<FHButtonDropdownProps> = ({
  selectionList,
  children,
  className,
  onClick,
  onSelectedItem,
  ...props
}: PropsWithChildren<FHButtonDropdownProps>) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const isIE = useIsIE();

  const renderEndIcon = () => {
    if (selectionList && selectionList.length > 0) {
      return open ? <ExpandLessIcon /> : <ExpandMoreIcon />;
    }
    return null;
  };
  return (
    <FHButton
      className={classnames(className, classes.button, {
        [classes.buttonExpanded]: open,
      })}
      {...props}
      onClick={(ev) => {
        if (selectionList && selectionList.length > 0) {
          setOpen(!open);
        } else {
          onClick && onClick(ev);
        }
      }}
      endIcon={renderEndIcon()}
      component={isIE && !props.href ? "div" : undefined}
      fhSize="md"
      fhStyle="secondary"
    >
      {children}
      {open && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Paper
            className={classes.selectionList}
            data-testid="fhButtonDropdownList"
          >
            {selectionList &&
              selectionList.map((item, i) => (
                <List component="nav" key={`${i}-${item.id}`} dense>
                  <ListItem
                    button
                    onClick={() => onSelectedItem && onSelectedItem(item)}
                  >
                    {!!item?.icon?.props?.src && (
                      <ListItemIcon>{item.icon}</ListItemIcon>
                    )}
                    {item.label}
                  </ListItem>
                </List>
              ))}
          </Paper>
        </ClickAwayListener>
      )}
    </FHButton>
  );
};
