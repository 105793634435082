import React, { memo, PropsWithChildren } from "react";

export type CustomVideoProps = {
  src: string;
  poster?: string;
  className: string;
  autoPlay?: boolean;
  controls?: boolean;
  handlePlay?: () => void;
  innerRef: React.RefObject<HTMLVideoElement>;
  muted?: boolean;
  loop?: boolean;
};

const CustomVideo = ({
  src,
  className,
  autoPlay,
  controls = false,
  poster,
  handlePlay,
  innerRef,
  muted = true,
  loop = true,
}: PropsWithChildren<CustomVideoProps>) => {
  return (
    <video
      {...{ src, poster, className }}
      ref={innerRef}
      onPlay={handlePlay}
      controls={controls}
      autoPlay={autoPlay}
      loop={loop}
      data-testid="bannerVideo"
      muted={muted}
      playsInline
    />
  );
};

const videoKey = (props: CustomVideoProps) =>
  JSON.stringify({
    src: props.src,
  });

export const MemoizedVideo = memo(
  CustomVideo,
  (prevProps, nextProps) => videoKey(prevProps) === videoKey(nextProps),
);
